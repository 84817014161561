import React, { useState, useEffect, useRef, useCallback } from "react";
import { glossary, TEMPLATE_APP, TEMPLATE_APP_ACCOUNTING, TEMPLATE_APP_CHART_OF_ACCOUNTS, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_TEAM, TEMPLATE_APP_USE_CASE } from "./Constants";
import { setCurrentAppId, setTemplateId } from "../../store/userAuthSlice";
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import SideNav from "./SideNav";
import {
  AppLayout,
  Button,
  ColumnLayout,
  Input,
  Icon,
  Textarea,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  ExpandableSection,
  ContentLayout,
  Table,
  Modal,
  Spinner,
  Badge,
  StatusIndicator,
  HelpPanel,
  Flashbar,
  Alert,
  Tabs,
  FileUpload,
  ButtonDropdown
} from "@amzn/awsui-components-react";
import { ColDef, ColumnGroupOpenedEvent, ExcelExportParams, ExcelStyle, GetRowIdParams, GridReadyEvent } from 'ag-grid-community';
import "../../index.scss";
import "./template.scss"
import { useSelector, useDispatch } from "react-redux";

import { useHistory, useParams } from "react-router-dom";

import favLogo from '../../images/star-regular-brown-for-home.svg'
import favLogoSolid from '../../images/star-solid-brown-for-home.svg'
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import CustomSelect from "./components/CustomSelect";
import CustomMultiSelect from "./components/CustomMultiSelect";
import CustomInput from "./components/CustomInput";
import CustomMultiInput from "./components/CustomMultiInput";
import CustomSingleInput from "./components/CustomSingleInput";
import CustomMultiHybridInputsForAppOutputs from "./components/CustomMultiHybridInputsForAppOutputs"
import { getEditableFields } from "./Utils/FormMetadata";
import { canAddEditDeleteApplication } from "./Utils/Permissions";
import { addApp, updateApp, deleteAppWithApproval, getSubLinks, getApprovalsForApp, getProcessForAppId, getProcessForAppIdPromise, createAuditLog, getLogicoProcessesForAppId, getSuggestedLogins, getQSDashboardEmbedLinksSync } from "./Utils/AppActivityUtils"
import CustomMultiHybridInputsForSourceTables from "./components/CustomMultiHybridInputsForSourceTables";
import CustomMultiHybridInputsWithPK from "./components/CustomMultiHybridInputsWithPK";
import CustomMultiHybridInputAndSelect from "./components/CustomMultiHybridInputAndSelect"
import { TEMPLATES, APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE } from 'src/Ateam-BI/Templates/Constants';
import Feedback from "src/generic-components/UserFeedback/Feedback";
import { createApprovalDraft, startApproval, startWASApproval } from "src/utilities/ApprovalAPIs";
import { loadFlexicoTemplates } from "src/utilities/FlexicoAPIs";
import CustomSelectAlias from "./components/CustomSelectAlias";
import CustomSelectMultiAlias from "./components/CustomSelectMultiAlias";
import { Storage } from 'aws-amplify';
import { configureGenericS3 } from "src/context/AuthContextUtility";
import * as d3 from "d3";
import { AgGridReact } from "ag-grid-react";
import appSettings from "src/Main/app_settings";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {
  const dispatch = useDispatch();
  const currentStage = useSelector((globalState) => globalState.auth.currentStage)
  const history = useHistory();
  const [NavOpen, setNavOpen] = useState(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const userId = useSelector((globalState) => globalState.auth.userId);

  //UseCase Details
  const [usecaseName, setUsecaseName] = useState('');
  const [usecaseDescription, setUsecaseDescription] = useState();
  const [usecaseStatus, setUsecaseStatus] = useState();
  const [usecaseComments, setUsecaseComments] = useState();
  const [usecaseDevComments, setUsecaseDevComments] = useState();
  const [usecaseType, setUsecaseType] = useState([]);
  const [usecaseRegions, setUsecaseRegions] = useState([]);
  const [usecaseLedgerImpact, setUsecaseLedgerImpact] = useState([]);
  const [usecaseLaunchMCM, setUsecaseLaunchMCM] = useState('');
  const [usecaseLaunchQQYY, setUsecaseLaunchQQYY] = useState();
  const [financialRelevance, setFinancialRelevance] = useState();
  const [usecaseRunFrequency, setUsecaseRunFrequency] = useState([]);
  const [usecaseMERSavings, setUsecaseMERSavings] = useState();
  const [usecaseMJECount, setUsecaseMJECount] = useState();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [deleteAppName, setDeleteAppName] = useState('');

  // Operation Details
  const [usecaseInputSourceTypes, setUsecaseInputSourceTypes] = useState([]);
  const [usecaseDataSourceDetails, setUsecaseDataSourceDetails] = useState([]);
  const [usecaseOutputType, setUsecaseOutputType] = useState([]);
  const [usecaseAutomationTools, setUsecaseAutomationTools] = useState([]);
  const [usecaseARD, setUsecaseARD] = useState();
  const [usecasePDD, setUsecasePDD] = useState();
  const [usecaseTDD, setUsecaseTDD] = useState();
  const [usecaseOutputLink, setUsecaseOutputLink] = useState();

  //Team Details
  const [developerTeam, setDeveloperTeam] = useState();
  const [processOwnerAlias, setProcessOwnerAlias] = useState();
  const [grcControlOwnerAlias, setGrcControlOwnerAlias] = useState();
  const [accountingOrg, setAccountingOrg] = useState();
  const [financialYearImpact, setFinancialYearImpact] = useState();
  const [accountingTeam, setAccountingTeam] = useState();
  const [accountingVP, setAccountingVP] = useState();
  const [accountingTeamEmail, setAccountingTeamEmail] = useState();

  const [toolsOpen, setToolsOpen] = useState(false);

  const [flashBarComments, setFlashBarComments] = useState();
  const [flashBarDevComments, setFlashBarDevComments] = useState();
  const [id, setId] = useState(props?.id);
  const [customSideNavitems, setCustomSideNavitems] = useState(props?.customSideNavitems)
  const [renderJson, setRenderJson] = useState(props?.renderJson)
  const [renderJsonEdited, setRenderJsonEdited] = useState(props?.renderJson)

  const [isFavorite, setIsFavorite] = useState(false);
  const [favDisabled, setFavDisabled] = useState(false);
  const [fieldMetadata, setFieldMetadata] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNewApp, setIsNewApp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [canDelete, setCanDelete] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [canAdd, setCanAdd] = useState(false)
  const [enableEditAction, setEnableEditAction] = useState(false)
  const [subLinks, setSubLinks] = useState([])
  const [modalMessage, setModalMessage] = useState('')
  const [subProcesses, setSubProcesses] = useState([])
  const [logicoProcesses, setLogicoProcesses] = useState([])

  const [updateComments, setUpdateComments] = useState("")
  const [modalError, setModalError] = useState("")
  const [alertErrorMessage, setAlertErrorMessage] = useState(null)
  const [flexicoTemplates, setFlexicoTemplates] = useState();
  const [suggestedLogins, setSuggestedLogins] = useState([])
  const [aliasesLoading, setAliasesLoading] = useState(false)
  const [activeTabId, setActiveTabId] = useState("details") 
  const [activeReportTabId, setActiveReportTabId] = useState()
  const [quicksightReports, setQuicksightReports] = useState([])
  const [embedUrl, setEmbedUrl] = useState()
  const [embedUrlLoadingMessage, setEmbedUrlLoadingMessage] = useState(null)
  const [annotationInputFile, setAnnotationInputFile] = useState([])
  const [dsInfo, setDsInfo] = useState()
  const [columnsList, setColumnsList] = useState([])
  const gridRef = useRef();
  const [annotationLoader, setAnnotationLoader] = useState(null)
  const [annotationError, setAnnotationError] = useState("error..")
  const [undoEditCount, setUndoEditCount] = useState(0) 
  const [selectedRowIndex, setSelectedRowIndex] = useState(0)
  const [showOldValues, setShowOldValues] = useState(0)

  const annotationFileName = 'annotation.csv'
  const annotationLevel = "public"
  const annotationPrefix = 'annotation/'

  const defaultProjectColDef = {
    resizable: true,
    sortable: true,
    autoHeaderHeight: true,
    filter: true,
    floatingFilter: false,
    filterParams: {
      applyMiniFilterWhileTyping: true
    },
    headerClass: 'header-center',
    editable: true,
    // singleClickEdit: true,
    enableCellChangeFlash: true,
    enableRowGroup: false,
    enableCellChangeFlash: true,
    cellClassRules: {
      'edited-row': (params) => { return params.data.isEdited}
      }
  };

  useEffect(() => {

    // setInitView()
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {
    gridRef?.current?.api?.refreshCells()
    return () => {
      // this now gets called when the component unmounts
    };
  }, [showOldValues]);


  useEffect(() => {
    setInitView()
    checkFavorite(id)
    return () => {
      // this now gets called when the component unmounts
    };
  }, [id]);

  useEffect(() => {
    checkFavorite(id)
  }, [userId]);

  useEffect( () => {
    getEmbedUrl()
  }, [activeReportTabId]);

  useEffect( () => {
    if(activeTabId == 'reports'){
      getEmbedUrl()
    }else if(activeTabId == 'frisco'){
      getAnnotationInput()
    }
  }, [activeTabId]);

  useEffect(() => {

    setFlashBarComments([{
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setFlashBarComments([]),
      content: (
        <>
          {usecaseComments}
        </>
      ),
      id: "comments"
    }])

  }, [usecaseComments])


  useEffect(() => {
    setFlashBarDevComments([{
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setFlashBarDevComments([]),
      content: (
        <>
          {usecaseDevComments}
        </>
      ),
      id: "comments"
    }])

  }, [usecaseDevComments])

  useEffect(() => {
    loadSideNav(subProcesses)
  }, [subProcesses, canEdit, subLinks, logicoProcesses, flexicoTemplates])

  useEffect(() => {
    // clean data
    let jsonData = cleanData(props.renderJson)
    setRenderJson(jsonData)
    setRenderJsonEdited(jsonData)
  }, [props.renderJson])


  useEffect(() => {
    loadView()
    setRenderJsonEdited(renderJson)
  }, [renderJson])

  useEffect(() => {
    checkIfFormIsSubmittable()
  }, [renderJsonEdited])
  const mandatoryFieldListener = (key, enableUpdate) => {
    mandatoryFieldsMissing[key] = enableUpdate
  }

  const getEmbedUrl = async() => {
    if(activeReportTabId == undefined){
      return
    }
    setEmbedUrlLoadingMessage("Loading report..")
    let outputs = renderJson[TEMPLATE_APP.OUTPUT].sort((a,b) => a[TEMPLATE_APP_OUTPUT.LABEL].localeCompare(b[TEMPLATE_APP_OUTPUT.LABEL]))
    
    if(isEditMode){
      outputs = renderJsonEdited[TEMPLATE_APP.OUTPUT].sort((a,b) => a[TEMPLATE_APP_OUTPUT.LABEL].localeCompare(b[TEMPLATE_APP_OUTPUT.LABEL]))
    }
    
    let dashboardId = outputs[activeReportTabId][TEMPLATE_APP_OUTPUT.VALUE]
      let region = 'us-east-1'
      let aws_account = outputs[activeReportTabId][TEMPLATE_APP_OUTPUT.AWS_ACCOUNT] || 'FAST'
      if( dashboardId.includes("http")){
        // its a url, fetch region and id
        let urlSplit =  dashboardId?.replace(/\/$/, '').split("/")
        dashboardId = urlSplit[urlSplit.length-1]
        if(urlSplit[2].includes("quicksight")){
          region = urlSplit[2].split(".")[0]
        }
      }
      setEmbedUrl(null)

    let response = await getQSDashboardEmbedLinksSync(aws_account,region,dashboardId)
    setEmbedUrl(response.url)
    setEmbedUrlLoadingMessage(null)
  }
  const setInitView = async () => {
    if(id){
      setSubLinks(getSubLinks(id))
      dispatch(setCurrentAppId(id));
      loadData();
      loadFlexico(id)
      setIsEditMode(false)
      setIsNewApp(false)
    }else{
      setIsNewApp(true)
    }
    loadEditableFields();
    let accessPerms;
    if(id && APPS_WITH_APP_LEVEL_PERMISSIONS_IN_TEMPLATE[currentStage?.toUpperCase()].includes(id)) {
      accessPerms = await canAddEditDeleteApplication(userId, id, currentStage)
    }
    else {
      accessPerms = await canAddEditDeleteApplication(userId, TEMPLATES.MAPLE, currentStage)
    }
      setCanDelete(accessPerms?.delete)
      setCanEdit(accessPerms?.edit)
      setCanAdd(accessPerms?.add)
    if (!id && accessPerms?.add) {
        setIsNewApp(true)
        setShowLoadingIcon(false);
        (!isEditMode) ? setIsEditMode(true) : ''
      }

      // !id means isNewApp=true. Sometimes the useeffect take time, so relying on id. id is a path param
    if(!id && !accessPerms?.add){
      setAlertErrorMessage(
        {
          "header":"Access denied",
          "message": "No access to add an application"
        }
      )
    }else{
      setAlertErrorMessage(null)
    }
  }

  const loadFlexico = (app_id) => {
    loadFlexicoTemplates(app_id, function onSuccess(result){
      setFlexicoTemplates(result.data.listFlexicoTemplates)
    }, function onFailure(error){

    })
  }

  const cleanData = (input) => {
    // some cases, DB responds with null if there is no acc team
    if(input.hasOwnProperty(TEMPLATE_APP.ACCOUNTING_TEAM) && input[TEMPLATE_APP.ACCOUNTING_TEAM]&& input[TEMPLATE_APP.ACCOUNTING_TEAM].length > 0 && !input[TEMPLATE_APP.ACCOUNTING_TEAM][0][TEMPLATE_APP_TEAM.TEAM]){
      input[TEMPLATE_APP.ACCOUNTING_TEAM] = null
    }
    return input
  }
  
  const loadEditableFields = async () => {
    let res = await getEditableFields()
    setFieldMetadata(res)
  }

  const loadSideNav = (subProcesses) => {

    let sideNavSubLinks = []
    subLinks?.forEach((subLink) => {
      sideNavSubLinks.push({
        "type": "link",
        "text": subLink.label,
        "href": subLink.url
      })
    })

    let subProcessSideNavLinks = []
    let logicoProcessSideNavLinks = []
    let flexicoProcessSideNavLinks = []
    subProcesses?.forEach((subProcess) => {
      subProcessSideNavLinks.push({
        "type": "link",
        "text": subProcess.label,
        "href": `/App/${id}/process/${subProcess.process_id}/history`
      })
    })

    flexicoTemplates?.forEach((template) => {
      flexicoProcessSideNavLinks.push({
        "type": "link",
        "text": template.label,
        "href": `/App/${id}/flexico/${template.flex_id}/history`
      })
    })

    logicoProcesses?.forEach((logicoProcess) => {
      logicoProcessSideNavLinks.push({
        "type": "link",
        "text": logicoProcess.display_label,
        "href": `/App/${id}/LogicoProcess/${logicoProcess.logico_process_id}/history`
      })
    })

    if (canEdit) {
      subProcessSideNavLinks.push({
        "type": "link",
        "text": <> <Badge>+</Badge> New process</>,
        "href": `/App/${id}/process`
      })

      logicoProcessSideNavLinks.push({
        "type": "link",
        "text": <> <Badge>+</Badge> New logico process</>,
        "href": `/App/${id}/LogicoProcess` 
      })

      flexicoProcessSideNavLinks.push({
        "type": "link",
        "text": <> <Badge>+</Badge> New flexico process</>,
        "href": `/App/${id}/flexico` 
      })
    }

    let sideNavLinks = []
    if(id){
      if(sideNavSubLinks.length > 0){
        sideNavLinks = sideNavLinks.concat(sideNavSubLinks)
      }

      let processesView = {
        type: "expandable-link-group",
        text: "FRISCO",
        href: "#",
        defaultExpanded: true,
        items: subProcessSideNavLinks
      }

      let logicoProcessesView = {
        type: "expandable-link-group",
        text: "LOGICO",
        href: "#",
        defaultExpanded: true,
        items: logicoProcessSideNavLinks
      }

      let flexicoProcessesView = {
        type: "expandable-link-group",
        text: "FlexiCo",
        href: "#",
        defaultExpanded: true,
        items: flexicoProcessSideNavLinks
      }
  
      if (subProcessSideNavLinks.length > 0) {
        sideNavLinks.push(processesView)
      }

      if (logicoProcessSideNavLinks.length > 0) {
        sideNavLinks.push(logicoProcessesView)
      }

      if (flexicoProcessSideNavLinks.length > 0) {
        sideNavLinks.push(flexicoProcessesView)
      }

      sideNavLinks.push({
        "type": "link",
        "text": "App Approvals",
        "href": `/ApprovalsHome/App/${id}`
    })
    sideNavLinks.push({
      "type": "link",
      "text": "App Audit Logs",
      "href": `/App/${id}/AppAuditLogs`
  })
    }
    

    if (sideNavLinks.length > 0) {
      sideNavLinks = sideNavLinks.concat(props?.customSideNavitems)
      setCustomSideNavitems(sideNavLinks)
    }
  }

  const getCurrentTime = () => {
    return Date.now();
  }

  const loadView = () => {
    setUsecaseName(renderJson[TEMPLATE_APP.APP_NAME]);
    setUsecaseDescription(renderJson[TEMPLATE_APP.APP_DESCRIPTION]);
    setUsecaseStatus(renderJson[TEMPLATE_APP.STATUS]);
    setUsecaseComments(renderJson[TEMPLATE_APP.FLASH_INFO_DESCRIPTION]);
    setUsecaseDevComments(renderJson[TEMPLATE_APP.DEV_ALERT_MESSAGES]);
    setUsecaseLedgerImpact(renderJson[TEMPLATE_APP.LEDGER_INFO]);
    setUsecaseLaunchMCM(renderJson[TEMPLATE_APP.LAUNCH_MCM]);
    setUsecaseLaunchQQYY(renderJson[TEMPLATE_APP.LAUNCH_QQ_YY]);
    setUsecaseRunFrequency(renderJson[TEMPLATE_APP.CADENCE]);
    setUsecaseMERSavings(renderJson[TEMPLATE_APP.ANNUAL_MER_SAVINGS]);
    setUsecaseMJECount(renderJson[TEMPLATE_APP.PROCESS_MJE_COUNT]);
    setUsecaseRegions(renderJson[TEMPLATE_APP.REGION]);
    setUsecaseType(renderJson[TEMPLATE_APP.USE_CASE]);
    setUsecaseDataSourceDetails(renderJson[TEMPLATE_APP.DATASOURCE]);
    setUsecaseARD(renderJson[TEMPLATE_APP.ARD]);
    setUsecasePDD(renderJson[TEMPLATE_APP.PDD]);
    setUsecaseTDD(renderJson[TEMPLATE_APP.TDD]);
    setUsecaseInputSourceTypes(
      renderJson[TEMPLATE_APP.INPUT_SOURCE_TYPE]
    );
    setUsecaseOutputType(renderJson[TEMPLATE_APP.OUTPUT]);
    setUsecaseAutomationTools(
      renderJson[TEMPLATE_APP.AUTOMATION_TOOLS]
    );
    setUsecaseOutputLink(renderJson[TEMPLATE_APP.OUTPUT]);

    setDeveloperTeam(renderJson[TEMPLATE_APP.DEVELOPER_TEAM.team]);
    setProcessOwnerAlias(renderJson[TEMPLATE_APP.PROCESS_OWNER]);
    setGrcControlOwnerAlias(renderJson[TEMPLATE_APP.GRC_CONTROL_OWNER]);
    setAccountingOrg(renderJson[TEMPLATE_APP.ORGANIZATION]);
    setFinancialRelevance(renderJson[TEMPLATE_APP.FINANCIAL_RELEVANCE]);
    setFinancialYearImpact(renderJson[TEMPLATE_APP.FINANCIAL_YEAR_IMPACT]);
    setAccountingTeam(renderJson[TEMPLATE_APP.ACCOUNTING_TEAM?.team]);
    setAccountingTeamEmail(renderJson[TEMPLATE_APP.ACCOUNTING_TEAM?.email]);
    setAccountingVP(renderJson[TEMPLATE_APP.VP]);
    setShowLoadingIcon(false);

    fetchEmbedView()
  }

  const loadData = async () => {
    if (!isNewApp && renderJson && Object.keys(renderJson).length > 0) {
      setShowLoadingIcon(true);
      if(renderJson.hasOwnProperty('processes')){
        if (renderJson['processes'] && renderJson['processes'].length > 0) {
          renderJson['processes'].sort((a,b) => {
            return a?.label.localeCompare(b?.label)
          })
        }
        setSubProcesses(renderJson['processes'])
        // loadSideNav(renderJson['processes'])
      }else{
        let response = await getProcessForAppIdPromise(id)
        if (response?.data?.getProcessesForAppIDV2) {
          response.data.getProcessesForAppIDV2?.sort((a, b) => {
            return a?.label.localeCompare(b?.label)
          })
          setSubProcesses(response.data.getProcessesForAppIDV2)
          // loadSideNav(response.data.getProcessesForAppIDV2)
        }
      }

      let response = await getLogicoProcessesForAppId(id)
        if (response?.data?.getLogicoProcessesForAppID) {
          const sorted = response?.data?.getLogicoProcessesForAppID?.sort((a,b)=>{return a?.display_label?.localeCompare(b?.display_label)})
          setLogicoProcesses(sorted)
        }
      loadView()
      setShowLoadingIcon(false);
    }
  };



  const checkFavorite = (appId) => {
    checkIsFavorite(appId, userId, function onResult(response) {
      setIsFavorite(response)
    })
  }

  const markFavorite = async (appId) => {
    setFavDisabled(true)
    await favorite(appId, userId, function onAdded(result) {
      setIsFavorite(true)
    }, function onDeleted(result) {
      setIsFavorite(false)
    }, function onFailure(result) {
    })
    setFavDisabled(false)
  }

  const isEditable = (fieldName, subKey = null) => {
    if (subKey) {
      return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['editable'])
    }
    else {
      return isNewApp || (isEditMode && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['editable'])
    }

  }

  const getSelectFieldValues = (fieldName, subKey = null) => {
    if (fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName]['values']) {
      if(fieldName === TEMPLATE_APP.STATUS && (usecaseStatus === 'Active' || usecaseStatus === 'Deprecated') && !isNewApp) {
        const index = fieldMetadata[fieldName]['values'].findIndex((option) => (option.value === 'WIP'))
        fieldMetadata[fieldName]['values'][index]["disabled"] = true
        fieldMetadata[fieldName]['values'][index]["disabledReason"] = 'Cannot update from "Active" or "Deprecated" status to "WIP".'
      } else if(fieldName === TEMPLATE_APP.STATUS && isNewApp) {
        fieldMetadata[fieldName]['values'].forEach((item) => {
          if(item.value === 'Active' || item.value === 'Deprecated') {
            item["disabled"] = true
            item["disabledReason"] = '"Active" and "Deprecated" statuses are disabled when creating an application, please select "WIP".'
          }
        })
      }
      return fieldMetadata[fieldName]['values']
    } else if (subKey && fieldMetadata && fieldMetadata[fieldName] && fieldMetadata[fieldName][subKey] && fieldMetadata[fieldName][subKey]['values']) {
      return fieldMetadata[fieldName][subKey]['values']
    } else {
      return []
    }
  }

  const getSuggestedLoginsCaller = async (fragment, size = 10) => {
    setSuggestedLogins([])
    setAliasesLoading(true)
    const response = await getSuggestedLogins(fragment, size)
    const data = JSON.parse(response.data.getSuggestedLogins)
    let suggestions = data["suggestions"]
    suggestions = suggestions.map((login) =>
    ({
        label: login?.toLowerCase(),
        value: login?.toLowerCase()
    }))
    setSuggestedLogins(suggestions)
    setAliasesLoading(false)
    return suggestions
}

  const getMultiInputFieldValues = (fieldName) => {
    if (renderJsonEdited && renderJsonEdited[fieldName]) {
      return renderJsonEdited[fieldName]
    } else {
      return []
    }
  }

  const updateValue = (fieldName, value, subKey = null) => {
    if (subKey) {
      if (!renderJsonEdited.hasOwnProperty(fieldName)) {
        renderJsonEdited[fieldName] = {}
      }
      renderJsonEdited[fieldName][subKey] = value
    } else {
      renderJsonEdited[fieldName] = value
    }
    setRenderJsonEdited(JSON.parse(JSON.stringify(renderJsonEdited)))
  }

  const getValue = (fieldName, subKey = null) => {
    if (subKey) {
      if (renderJsonEdited.hasOwnProperty(fieldName)) {
        return renderJsonEdited[fieldName][subKey]
      } else {
        return null
      }
    } else {
      return renderJsonEdited ? renderJsonEdited[fieldName] : null
    }
  }

  const getMultiSelectedOptions = (fieldName) => {
    let arr = renderJsonEdited[fieldName]
    let selectedOptions = []
    if (arr) {
      arr.forEach((item) => {
        selectedOptions.push({ label: item, value: item })
      })
    }
    return selectedOptions
  }

  const cancelEdition = () => {
    setErrorMessage("")
    setIsEditMode(false)
    setRenderJsonEdited(renderJson)
  }

  const editApplication = () => {
    setIsEditMode(true)
    setRenderJsonEdited(renderJson)
  }

  const checkIfNull = (data, field, missingFields) => {
    if (!data.hasOwnProperty(field) || !data[field]) {
      missingFields.push(field)
    }
  }
  const getMissingFields = (data, fields) => {
    let missingFields = []
    fields.forEach((field) => {
      if (typeof field == 'string') {
        checkIfNull(data, field, missingFields)
      } else {
        if (field?.mainKey) {
          field.subKeys.forEach(subKey => {
            data[field.mainKey]?.forEach((item) => {
              checkIfNull(item, subKey, missingFields)
            })
          })
        }
      }
    })
    return missingFields
  }

  const addApplication = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(renderJsonEdited, [TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.APP_NAME])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else {
      setShowLoadingIcon(true)
      renderJsonEdited[TEMPLATE_APP.LAST_UPDATED_BY] = userId
      renderJsonEdited[TEMPLATE_APP.CREATED_BY] = userId
      renderJsonEdited[TEMPLATE_APP.TEMPLATE_ID] = TEMPLATES.MAPLE
      // setRenderJson(renderJsonEdited)
      if(renderJsonEdited.hasOwnProperty("processes")){
        delete renderJsonEdited['processes']
      }
      createAuditLog(`APP`, `ADD_REQ#${getCurrentTime()}`, updateComments, JSON.stringify({}), JSON.stringify(renderJsonEdited), "ADD REQ", userId)
      addApp(renderJsonEdited, function onSuccess(response) {
        setIsNewApp(false)
        setRenderJson(JSON.parse(JSON.stringify(response.data.addAppV2)))
        setShowLoadingIcon(false)
        history.push("/App/" + response.data.addAppV2.app_id)
        setIsFavorite(false)
        setId(response.data.addAppV2.app_id);
        cancelEdition()
        setQuicksightReports([])
      }, function onFailure(error) {
        setErrorMessage(error?.errors[0]?.message)
        setShowLoadingIcon(false)
      })
    }
  }

  const deleteApplication = () => {
    if(updateComments){
      setShowLoadingIcon(true)
    setErrorMessage("")
    setModalMessage("")
    setUpdateComments("")
    dismissDeleteDialog()
    getApprovalsForApp(id, async function onSuccess(response) {
      if (response.data.getApprovalForAppV2?.length) {
        //error
        setShowLoadingIcon(false)
        setErrorMessage("")
        setModalMessage(`This app is already scheduled for deletion. Approval id : ${response.data.getApprovalForAppV2[0].approval_id}`)
      } else {
        createAuditLog(`APP#${id}`, `DEL_REQ#${getCurrentTime()}`, updateComments, JSON.stringify({renderJson}), JSON.stringify({}), "DEL REQ", userId)
        let resp = await startWASApproval(
          {
            userAlias: userId,
            stage: currentStage,
            appId: id,
            appName: renderJson[TEMPLATE_APP.APP_NAME],
            comments: updateComments,
            approveResponseNeeded:1
          })
        if(resp?.workflowInstanceId){
          createAuditLog(`APP#${id}`, `DEL_REQ#${resp?.workflowInstanceId}`, updateComments, JSON.stringify({renderJson}), JSON.stringify({}), "DEL REQ", userId)
          deleteAppWithApproval(id, resp.workflowInstanceId, function onSuccess(response) {
            setShowLoadingIcon(false)
            setModalMessage(`Approval request raised. Your app will be deleted once Approved. Approval ID : ${resp.workflowInstanceId}. Please save this approval ID for future references.`)
            // history.push("/")
          }, function onFailure(error) {
            setShowLoadingIcon(false)
            setModalMessage(`An approval was created for deletion. But the auto app delete trigger creation has failed. Please contact admin/ raise tt with this approval ID in the description : ${resp.workflowInstanceId}`)
          })
        }else{
          setModalMessage("Could not create approval for deletion. Please try again")
        }
      }
    }, function onFailure(response) {
      setShowLoadingIcon(false)
      setErrorMessage("Something went wrong. Cannot fetch existing approvals for the app. Please contact admin/ raise a tt")
    })
    }else{
      setModalError("Please provide comments with the reason for deletion")
    }
  
  }

  const updateApplication = () => {
    setErrorMessage("")
    let missingFields = getMissingFields(renderJsonEdited, [TEMPLATE_APP.APP_ID, TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.APP_NAME])
    if (missingFields && missingFields.length > 0) {
      setErrorMessage('missing mandatory fields : ' + missingFields.join(","))
    } else {
      setShowLoadingIcon(true)
      renderJsonEdited[TEMPLATE_APP.LAST_UPDATED_BY] = userId
      renderJsonEdited[TEMPLATE_APP.TEMPLATE_ID] = TEMPLATES.MAPLE
      if(renderJsonEdited.hasOwnProperty("processes")){
        delete renderJsonEdited['processes']
      }
      if (updateComments) {
        createAuditLog(`APP#${id}`, `${getCurrentTime()}`, updateComments, JSON.stringify(renderJson), JSON.stringify(renderJsonEdited), "UPDATE REQ", userId)
        setUpdateComments("")
        updateApp(cleanData(renderJsonEdited), function onSuccess(response) {
          setRenderJson(JSON.parse(JSON.stringify(response.data.updateAppV2)))
          setShowLoadingIcon(false)
          cancelEdition()
          if (props?.redirectOnUpdate) {
            history.push(props.redirectOnUpdate)
          } else {
            history.push("/App/" + response.data.updateAppV2.app_id)
          }
          setId(response.data.updateAppV2.app_id);
          setQuicksightReports([])
        }, function onFailure(error) {
          setErrorMessage(error?.errors[0]?.message)
          setShowLoadingIcon(false)
        })
      }else {
        setShowLoadingIcon(false)
        setErrorMessage("Please provide your comments for the update")
      }
      
    }
  }

  const fetchEmbedView = async() => {
    setQuicksightReports([])
    // setEmbedUrl()
    // setActiveReportTabId()
    let quicksightReports = []
    let outputs = renderJson[TEMPLATE_APP.OUTPUT].sort((a,b) => a[TEMPLATE_APP_OUTPUT.LABEL].localeCompare(b[TEMPLATE_APP_OUTPUT.LABEL]))

    if(isEditMode){
      outputs = renderJsonEdited[TEMPLATE_APP.OUTPUT].sort((a,b) => a[TEMPLATE_APP_OUTPUT.LABEL].localeCompare(b[TEMPLATE_APP_OUTPUT.LABEL]))
    }
    // the QS can be any index, find the least
    let tabId = outputs.length
    for(let i=0;i<outputs.length;i++){
      let output = outputs[i]
      if(output[TEMPLATE_APP_OUTPUT.TYPE].toLowerCase() != 'quicksight dashboard' ){
        continue
      }
      if(tabId>i){
        tabId=i
        setActiveReportTabId(`${i}`)
      }
      
        quicksightReports.push({
          'id':`${i}`, 
          'label': output[TEMPLATE_APP_OUTPUT.LABEL],
          'content': 
          <div>
          </div>

        })
      
    }
    setQuicksightReports(quicksightReports)

  }
  const checkIfFormIsSubmittable = () => {
    let missingFields = getMissingFields(renderJsonEdited,
      [TEMPLATE_APP.APP_ID, TEMPLATE_APP.APP_DESCRIPTION, TEMPLATE_APP.APP_NAME, { mainKey: TEMPLATE_APP.ACCOUNTING_TEAM, subKeys: [TEMPLATE_APP_TEAM.TEAM] },
      { mainKey: TEMPLATE_APP.OUTPUT, subKeys: [TEMPLATE_APP_OUTPUT.TYPE] }])
    setEnableEditAction(missingFields.length <= 0)
  }
  const getDate = (value) => {
    var d = new Date(parseFloat(value) * 1000)
    return d.toLocaleString()
  }
  const dismissDeleteDialog = () => {
    setDeleteAppName('')
    setDeleteConfirmationModal(false)
  }
  const postProcessTeamEmails = (value) => {
    return value.replaceAll("@amazon.com", "")
  }

  const lastUpdatedContent = (<>
    {getValue(TEMPLATE_APP.LAST_UPDATED_TIME_STAMP) ? <>

      <span className="last_updated"> Last updated : <span className="bold">{getDate(getValue(TEMPLATE_APP.LAST_UPDATED_TIME_STAMP))}</span></span>
      {getValue(TEMPLATE_APP.LAST_UPDATED_BY) ? <span className="last_updated"> by  <span className="bold">{getValue(TEMPLATE_APP.LAST_UPDATED_BY)}</span></span> : ''}
    </> : ''}
  </>)

  const accountingValidation = (elements) => {
    let emptyTeam = true
    elements.forEach((element) => {
      if (!element.team) {
        emptyTeam = false
      }
    })
    return emptyTeam
  }

  const uploadAnnotationInput = async () => {
    configureGenericS3()
    const file = annotationInputFile[0]
    let level = annotationLevel
    let prefix = annotationPrefix
    let putResp = await Storage.vault.put(annotationFileName, file, {
                    level: level,
                    customPrefix: { public: prefix },
                    acl: 'bucket-owner-full-control'
    })
    getAnnotationInput()
  }

  const getAnnotationInput = async () => {
    setAnnotationLoader('Loading..')
    setAnnotationError(null)
    setDsInfo(null)
    setColumnsList(null)
    configureGenericS3()
    let level = annotationLevel
    let prefix = annotationPrefix
    let fileURL = await Storage.get(annotationFileName, { level: level, customPrefix: { public: prefix }, expires: 60 });
    let csvdata = await d3.csv(fileURL).then((result) => {
      setDsInfo(result);
      if (result?.columns) {
        const definitions = result?.columns.map(colName => {
          let def = { field: colName , resizable: true, sortable: true,width:"150px", cellClass: (params) => {
      return params?.data?.isEdited?.hasOwnProperty(colName) ? 'edited-cell' : '';
    }  
  }
          if(colName == 'SEC Deliverable Number'){
            def['editable'] = false
          }
          if(colName == 'Period - Quarter'){
            def['cellEditor'] = 'agSelectCellEditor'
            def['cellEditorParams'] = {
              values: ["Q1", "Q2", "Q3", "Q4"],
            }
          }
          return def
         })
        setColumnsList(definitions)
      }
      setAnnotationLoader(null)
      setAnnotationError(null)
    }).catch((response) => {
      setAnnotationLoader(null)
      setAnnotationError('Loading failed')
      console.log(response)
    });
  }

  const genericValueGetter =(params) => {
    console.log(params.data.orgsmId + JSON.stringify(this.organisms));
    return this.countries.filter(item => item.cotryId === params.data.cotryId).name;
}

  const appDetails = (
    <div>
    <SpaceBetween size="l" >
          <Grid
            gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
          >
            <Container
            >
              <SpaceBetween>
                <div className="header_menu">
                  <div className="header_left">
                    <Box variant="h1">Application Details</Box>
                  </div>
                </div>
                <br></br>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ColumnLayout columns={2}>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Process Output links"
                    >
                      {
                        isEditable(TEMPLATE_APP.OUTPUT) ?
                          <CustomMultiHybridInputsForAppOutputs field={TEMPLATE_APP.OUTPUT}
                            subKeyForSelection={TEMPLATE_APP.OUTPUT_SUB.TYPE} mandatoryFieldListener={mandatoryFieldListener}
                            updateValue={updateValue} getOptions={getSelectFieldValues} getValues={getValue} />
                          :
                          <>
                            <br></br>
                            {getValue(TEMPLATE_APP.OUTPUT) ? <Table
                              columnDefinitions={[
                                {
                                  id: "type",
                                  header: "Type",
                                  cell: item => item[TEMPLATE_APP_OUTPUT.TYPE],
                                  isRowHeader: true
                                },
                                {
                                  id: "value",
                                  header: "Link",
                                  cell: item => <Link href={item[TEMPLATE_APP_OUTPUT.VALUE]} external>{item.hasOwnProperty(TEMPLATE_APP_OUTPUT.LABEL) ? item[TEMPLATE_APP_OUTPUT.LABEL] : item[TEMPLATE_APP_OUTPUT.TYPE]}</Link>,
                                  sortingField: "alt"
                                }
                              ]}
                              items={getValue(TEMPLATE_APP.OUTPUT)}
                              loadingText="Loading team details"
                              sortingDisabled
                              empty={
                                <Box
                                  margin={{ vertical: "xs" }}
                                  textAlign="center"
                                  color="inherit"
                                >
                                  <SpaceBetween size="m">
                                    <b>No resources</b>
                                  </SpaceBetween>
                                </Box>
                              }
                              variant='embedded'
                            /> : ''}
                          </>
                      }
                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Permissions"
                    >
                      {
                        isEditable(TEMPLATE_APP.PERMISSION) ?
                          <CustomMultiHybridInputsWithPK field={TEMPLATE_APP.PERMISSION} subKeys={[{ subKey: TEMPLATE_APP_PERMISSION.NAME, placeholder: 'Name/Description' }, { subKey: TEMPLATE_APP_PERMISSION.LINK, placeholder: 'URL/Link' }]}
                            pk={TEMPLATE_APP_PERMISSION.APP_PERMISSION_PK} updateValue={updateValue} getValues={getValue} />
                          :
                          <ul>
                            {getValue(TEMPLATE_APP.PERMISSION)?.map((item) => (
                              (item.hasOwnProperty(TEMPLATE_APP_PERMISSION.LINK) ? <li key={item} className="list-item-data">
                                <Box variant="p">
                                  <Link href={item.link} external>{item.name ? item.name : item.link}</Link>
                                </Box>
                              </li> : '')
                            ))}
                          </ul>

                      }
                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Organizations Supported"
                    >
                      {
                        isEditable(TEMPLATE_APP.ORGANIZATION) ?
                          <CustomMultiSelect field={TEMPLATE_APP.ORGANIZATION} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                          :
                          <ul>
                            {accountingOrg?.map((item) => (
                              <li key={item} className="list-item-data">
                                {item}
                              </li>
                            ))}
                          </ul>

                      }
                    </ExpandableSection>

                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Run Frequency"
                    >
                      {
                        isEditable(TEMPLATE_APP.CADENCE) ?
                          <CustomMultiSelect field={TEMPLATE_APP.CADENCE}
                            updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                          :
                          <ul>
                            {usecaseRunFrequency?.map((item) => (
                              <li key={item} className="list-item-data">
                                {item}
                              </li>
                            ))}
                          </ul>

                      }
                    </ExpandableSection>
                  </div>

                  <div>
                    <Box variant="h4">Contact DEV TEAM for this Process</Box>
                    {
                      isEditable(TEMPLATE_APP.SIM_TICKET_URL) ?
                        <>
                          <CustomInput field={TEMPLATE_APP.SIM_TICKET_URL} placeholder="SIM ticket url" updateValue={updateValue} getValue={getValue}></CustomInput>
                          <CustomInput field={TEMPLATE_APP.SIM_TICKET_LABEL} placeholder="label" updateValue={updateValue} getValue={getValue}></CustomInput>
                        </>
                        : <Box>{getValue(TEMPLATE_APP.SIM_TICKET_URL) ?
                          <Box variant="p">
                            <Button
                              href={getValue(TEMPLATE_APP.SIM_TICKET_URL)}
                              iconAlign="right"
                              iconName="external"
                              target="_blank"
                            >
                              {getValue(TEMPLATE_APP.SIM_TICKET_LABEL) ? getValue(TEMPLATE_APP.SIM_TICKET_LABEL) : 'Raise a ticket'}
                            </Button>
                          </Box> :
                          <Box>
                            Not Available
                          </Box>
                        }</Box>
                    }
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Use Case Type"
                    >
                      {
                        isEditable(TEMPLATE_APP.USE_CASE) ?
                          <CustomMultiSelect field={TEMPLATE_APP.USE_CASE} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                          :
                          <ul>
                            {usecaseType?.map((item) => (
                              <li key={item} className="list-item-data">
                                {item}
                              </li>
                            ))}
                          </ul>

                      }
                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Financial Relevance"
                    >
                      {
                        isEditable(TEMPLATE_APP.FINANCIAL_RELEVANCE) ?
                          <CustomMultiSelect field={TEMPLATE_APP.FINANCIAL_RELEVANCE} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                          :
                          <ul>
                            {financialRelevance?.map((item) => (
                              <li key={item} className="list-item-data">
                                {item}
                              </li>
                            ))}
                          </ul>

                      }
                    </ExpandableSection>

                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Embed quicksight dashboard"
                    >
                      {
                        isEditable(TEMPLATE_APP.FINANCIAL_RELEVANCE) ?
                          <CustomMultiSelect field={TEMPLATE_APP.FINANCIAL_RELEVANCE} updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                          :
                          <ul>
                            {financialRelevance?.map((item) => (
                              <li key={item} className="list-item-data">
                                {item}
                              </li>
                            ))}
                          </ul>

                      }
                    </ExpandableSection>

                  </div>
                </ColumnLayout>
              </SpaceBetween>

            </Container>
            <Container
              header={
                <Header
                  variant="h2"
                >
                  Team Details
                </Header>
              }
            >

              <SpaceBetween>
                <br></br>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ColumnLayout columns={2}>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Process Owner (s)"
                    >
                      {
                        isEditable(TEMPLATE_APP.PROCESS_OWNER) ?
                          <CustomSelectMultiAlias field={TEMPLATE_APP.PROCESS_OWNER} getValues={getMultiInputFieldValues} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading} />

                          :
                          <ul>
                            {getMultiInputFieldValues(TEMPLATE_APP.PROCESS_OWNER)?.map((item) => (
                              <li key={item.trim()} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank">
                                  {item.trim()}
                                </Link>
                              </li>
                            ))}
                          </ul>
                      }

                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Accounting VP"
                    >
                      {
                        isEditable(TEMPLATE_APP.VP) ?
                        <CustomSelectMultiAlias field={TEMPLATE_APP.VP} getValues={getMultiInputFieldValues} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading}/>
                          :
                          <ul>
                            {getMultiInputFieldValues(TEMPLATE_APP.VP)?.map((item) => (
                              <li key={item} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank"> {item.trim()} </Link>
                              </li>
                            ))}
                          </ul>
                      }
                    </ExpandableSection>
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="Accounting team"
                    >{
                        isEditable(TEMPLATE_APP.ACCOUNTING_TEAM) ?
                          <CustomMultiHybridInputAndSelect field={TEMPLATE_APP.ACCOUNTING_TEAM}
                            inputKey={TEMPLATE_APP.TEAM_SUB.EMAIL} selectKey={TEMPLATE_APP.TEAM_SUB.TEAM} selectHint="Choose team"
                            placeholder="email" getValues={getValue} postProcess={postProcessTeamEmails} validator={accountingValidation} mandatoryFieldListener={mandatoryFieldListener}
                            updateValue={updateValue} getOptions={getSelectFieldValues} info='For emails, type the alias only (without @amazon.com)' />
                          :
                          <div className="teams_section">
                            {getValue(TEMPLATE_APP.ACCOUNTING_TEAM) ? <Table
                              columnDefinitions={[
                                {
                                  id: "variable",
                                  header: "Team",
                                  cell: item => item.team || "-",
                                  isRowHeader: true
                                },
                                {
                                  id: "alt",
                                  header: "Alias",
                                  cell: item => item.email || "-",
                                  sortingField: "alt"
                                }
                              ]}
                              items={getValue(TEMPLATE_APP.ACCOUNTING_TEAM)}
                              loadingText="Loading team details"
                              sortingDisabled
                              empty={
                                <Box
                                  margin={{ vertical: "xs" }}
                                  textAlign="center"
                                  color="inherit"
                                >
                                  <SpaceBetween size="m">
                                    <b>No resources</b>
                                  </SpaceBetween>
                                </Box>
                              }
                              variant='embedded'
                            /> : ''}
                          </div>
                      }</ExpandableSection>
                  </div>
                  <div></div>
                  <div>
                    <Box variant="h4">Developer Team</Box>
                    {
                      isEditable(TEMPLATE_APP.DEVELOPER_TEAM) ?
                        <CustomSelect field={TEMPLATE_APP.DEVELOPER_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.TEAM} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                        :
                        <Box variant="p">{getValue(TEMPLATE_APP.DEVELOPER_TEAM, TEMPLATE_APP.TEAM_SUB.TEAM)}</Box>
                    }
                  </div>

                  <div>
                    <Box variant="h4">Developer Team Email</Box>
                    {
                      isEditable(TEMPLATE_APP.DEVELOPER_TEAM) ?
                        <CustomInput field={TEMPLATE_APP.DEVELOPER_TEAM} subKey={TEMPLATE_APP.TEAM_SUB.EMAIL}
                          placeholder="email" updateValue={updateValue} getValue={getValue} postProcess={postProcessTeamEmails}
                          info='For emails, type the alias only (without @amazon.com)'
                        ></CustomInput>
                        :
                        <Box variant="p">{getValue(TEMPLATE_APP.DEVELOPER_TEAM, TEMPLATE_APP.TEAM_SUB.EMAIL)}</Box>
                    }
                  </div>

                  <div>
                    <ExpandableSection
                      variant="navigation"
                      defaultExpanded={true}
                      headerText="GRC Control Owner"
                    >
                      {
                        isEditable(TEMPLATE_APP.GRC_CONTROL_OWNER) ?
                        <CustomSelectAlias field={TEMPLATE_APP.GRC_CONTROL_OWNER} getValue={getValue} updateValue={updateValue} options={suggestedLogins} getOptions={getSuggestedLoginsCaller} aliasesLoading={aliasesLoading} />
                          :
                          <ul>
                            {getMultiInputFieldValues(TEMPLATE_APP.GRC_CONTROL_OWNER)?.map((item) => (
                              <li key={item.trim()} className="list-item-data">
                                <Link href={`https://phonetool.amazon.com/users/${item.trim()}`} target="_blank">
                                  {item.trim()}
                                </Link>
                              </li>
                            ))}
                          </ul>
                      }

                    </ExpandableSection>
                  </div>

                  <div>
                    <Box variant="h4">Created by</Box>
                    <Link href={`https://phonetool.amazon.com/users/${getValue(TEMPLATE_APP.CREATED_BY)}`} target="_blank"> {getValue(TEMPLATE_APP.CREATED_BY)} </Link>
                  </div>
                </ColumnLayout>
              </SpaceBetween>

            </Container>
          </Grid>
          <Container
            header={
              <Header
                variant="h2"
              >
                Operational Details
              </Header>
            }
          >
            <SpaceBetween size="l">
              <ColumnLayout columns={1}>
                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Source data tables"
                  >
                    {
                      isEditable(TEMPLATE_APP.SOURCE_DATA_TABLES) ?
                        <CustomMultiHybridInputsForSourceTables field={TEMPLATE_APP.SOURCE_DATA_TABLES}
                          updateValue={updateValue} getValues={getValue} />
                        :
                        <Box variant="p">
                          {getValue(TEMPLATE_APP.SOURCE_DATA_TABLES) ? <Table
                            columnDefinitions={[
                              {
                                id: "upstream_source",
                                header: "Upstream source",
                                cell: item => item[TEMPLATE_APP_SOURCE_DATA_TABLES.UPSTREAM_SOURCE],
                                isRowHeader: true
                              },
                              {
                                id: "source_schema",
                                header: "Schema",
                                cell: item => item[TEMPLATE_APP_SOURCE_DATA_TABLES.SCHEMA],
                                sortingField: "alt"
                              },
                              {
                                id: "source_database",
                                header: "Database",
                                cell: item => item[TEMPLATE_APP_SOURCE_DATA_TABLES.DATABASE],
                                sortingField: "alt"
                              },
                              {
                                id: "source_table",
                                header: "Table",
                                cell: item => item[TEMPLATE_APP_SOURCE_DATA_TABLES.TABLE],
                                sortingField: "alt"
                              },
                              {
                                id: "description",
                                header: "Description",
                                cell: item => item[TEMPLATE_APP_SOURCE_DATA_TABLES.DESCRIPTION],
                                sortingField: "alt"
                              }
                            ]}
                            items={getValue(TEMPLATE_APP.SOURCE_DATA_TABLES)}
                            loadingText="Loading team details"
                            sortingDisabled
                            empty={
                              <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                              >
                                <SpaceBetween size="m">
                                  <b>No resources</b>
                                </SpaceBetween>
                              </Box>
                            }
                            variant='embedded'
                          /> : ''}
                        </Box>


                    }
                  </ExpandableSection>
                </div>

              </ColumnLayout>
              <ColumnLayout columns={3}>

                <div>
                  <Box variant="h4">Accounting Requirements Document (ARD)</Box>
                  {
                    isEditable(TEMPLATE_APP.ARD) ?
                      <CustomInput field={TEMPLATE_APP.ARD} placeholder="ARD" updateValue={updateValue} getValue={getValue}></CustomInput>
                      : <Box>{getValue(TEMPLATE_APP.ARD) ?
                        <Box variant="p">
                          <Link href={getValue(TEMPLATE_APP.ARD)} external>ARD</Link>
                        </Box> :
                        <Box>
                          Not Available
                        </Box>
                      }</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">Project Design Document (PDD)</Box>
                  {
                    isEditable(TEMPLATE_APP.PDD) ?
                      <CustomInput field={TEMPLATE_APP.PDD} placeholder="PDD" updateValue={updateValue} getValue={getValue}></CustomInput>
                      : <Box>{getValue(TEMPLATE_APP.PDD) ?
                        <Box variant="p">
                          <Link href={getValue(TEMPLATE_APP.PDD)} external>PDD</Link>
                        </Box> :
                        <Box>
                          Not Available
                        </Box>
                      }</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">Technical Design Document (TDD)</Box>
                  {
                    isEditable(TEMPLATE_APP.TDD) ?
                      <CustomInput field={TEMPLATE_APP.TDD} placeholder="TDD" updateValue={updateValue} getValue={getValue}></CustomInput>
                      : <Box>{getValue(TEMPLATE_APP.TDD) ?
                        <Box variant="p">
                          <Link href={getValue(TEMPLATE_APP.TDD)} external>TDD</Link>
                        </Box> :
                        <Box>
                          Not Available
                        </Box>
                      }</Box>
                  }
                </div>

                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="GL accounts"
                  >
                    {
                      isEditable(TEMPLATE_APP.CHART_OF_ACCOUNT) ?
                        <CustomMultiHybridInputsWithPK field={TEMPLATE_APP.CHART_OF_ACCOUNT} subKeys={[{ subKey: TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS, placeholder: 'COA # only' }]}
                          pk={TEMPLATE_APP_CHART_OF_ACCOUNTS.CHART_OF_ACCOUNTS_PK} updateValue={updateValue} getValues={getValue} />
                        :
                        <ul>
                          {getValue(TEMPLATE_APP.CHART_OF_ACCOUNT)?.map((item) => (
                            (item.hasOwnProperty(TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS) ? <li key={item} className="list-item-data">
                              {item[TEMPLATE_APP_CHART_OF_ACCOUNTS.FPA_DIM_ACCOUNTS]}
                            </li> : '')
                          ))}
                        </ul>

                    }
                  </ExpandableSection>
                </div>
                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Input Source Types"
                  >
                    {
                      isEditable(TEMPLATE_APP.INPUT_SOURCE_TYPE) ?
                        <CustomMultiSelect field={TEMPLATE_APP.INPUT_SOURCE_TYPE}
                          updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                        :
                        <ul>
                          {usecaseInputSourceTypes?.map((item) => (
                            <li key={item} className="list-item-data">
                              {item}
                            </li>
                          ))}
                        </ul>

                    }
                  </ExpandableSection>
                </div>

                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Automation Tools"
                  >
                    {
                      isEditable(TEMPLATE_APP.AUTOMATION_TOOLS) ?
                        <CustomMultiSelect field={TEMPLATE_APP.AUTOMATION_TOOLS}
                          updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                        :
                        <ul>
                          {usecaseAutomationTools?.map((item) => (
                            <li key={item} className="list-item-data">
                              {item}
                            </li>
                          ))}
                        </ul>

                    }
                  </ExpandableSection>
                </div>

              </ColumnLayout>
            </SpaceBetween>
          </Container>

          <Container
            header={
              <Header
                variant="h2"
              >
                Other Details
              </Header>
            }
          >
            <SpaceBetween size="l">
              <ColumnLayout columns={3}>

                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Ledger Impact"
                  >
                    {
                      isEditable(TEMPLATE_APP.LEDGER_INFO) ?
                        <CustomMultiSelect field={TEMPLATE_APP.LEDGER_INFO}
                          updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                        :
                        <ul>
                          {usecaseLedgerImpact?.map((item) => (
                            <li key={item} className="list-item-data">
                              {item}
                            </li>
                          ))}
                        </ul>

                    }
                  </ExpandableSection>
                </div>
                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Use Case Regions"
                  >
                    {
                      isEditable(TEMPLATE_APP.REGION) ?
                        <CustomMultiSelect field={TEMPLATE_APP.REGION}
                          updateValue={updateValue} getValues={getSelectFieldValues} getSelectedOptions={getMultiSelectedOptions} />
                        :
                        <ul>
                          {usecaseRegions?.map((item) => (
                            <li key={item} className="list-item-data">
                              {item}
                            </li>
                          ))}
                        </ul>

                    }
                  </ExpandableSection>
                </div>
                <div>
                  <ExpandableSection
                    variant="navigation"
                    defaultExpanded={true}
                    headerText="Launch MCM"
                  >
                    {
                      isEditable(TEMPLATE_APP.LAUNCH_MCM) ?
                        <CustomMultiInput field={TEMPLATE_APP.LAUNCH_MCM} updateValue={updateValue} getValues={getMultiInputFieldValues}></CustomMultiInput>
                        :
                        <ul>
                          {getMultiInputFieldValues(TEMPLATE_APP.LAUNCH_MCM)?.map((item) => (
                            <li key={item} className="list-item-data">
                              {item.trim()}
                            </li>
                          ))}
                        </ul>
                    }
                  </ExpandableSection>
                </div>
                <div>
                  <Box variant="h4">Launch Date</Box>
                  {
                    isEditable(TEMPLATE_APP.LAUNCH_QQ_YY) ?
                      <CustomSelect field={TEMPLATE_APP.LAUNCH_QQ_YY} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                      : <Box variant="p">{getValue(TEMPLATE_APP.LAUNCH_QQ_YY)}</Box>
                  }
                </div>
                <div>
                  <Box variant="h4">Annual MER Savings</Box>
                  {
                    isEditable(TEMPLATE_APP.ANNUAL_MER_SAVINGS) ?
                      <CustomInput field={TEMPLATE_APP.ANNUAL_MER_SAVINGS} placeholder="Annual MER savings" updateValue={updateValue} getValue={getValue}></CustomInput>

                      : <Box variant="p">{getValue(TEMPLATE_APP.ANNUAL_MER_SAVINGS)}</Box>
                  }
                </div>
                <div>
                  <Box variant="h4">MJE Count</Box>
                  {
                    isEditable(TEMPLATE_APP.PROCESS_MJE_COUNT) ?
                      <CustomInput field={TEMPLATE_APP.PROCESS_MJE_COUNT} placeholder="MJE count" updateValue={updateValue} getValue={getValue}></CustomInput>
                      :
                      <Box variant="p">{getValue(TEMPLATE_APP.PROCESS_MJE_COUNT)}</Box>
                  }
                </div>

                <div>
                  <Box variant="h4">Financial year impact</Box>
                  {
                    isEditable(TEMPLATE_APP.FINANCIAL_YEAR_IMPACT) ?
                      <CustomSelect field={TEMPLATE_APP.FINANCIAL_YEAR_IMPACT} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                      : <Box variant="p">{getValue(TEMPLATE_APP.FINANCIAL_YEAR_IMPACT)}</Box>
                  }
                </div>
                


              </ColumnLayout>
            </SpaceBetween>
          </Container>

          <Box>
            {errorMessage && (
              <Box variant="p" color="text-status-error">
                {errorMessage}
              </Box>
            )}
          </Box>
          {
            (isNewApp && canAdd) ?
              <div className="buttons">
                <SpaceBetween direction="horizontal" size="l">
                  {enableEditAction ? <Button variant="primary" onClick={addApplication} >Save</Button> :
                    <Button variant="primary" onClick={addApplication} >Save</Button>}

                </SpaceBetween>
              </div>
              :
              ''
          }
          {
            !isNewApp && isEditMode ?
            <SpaceBetween direction="vertical" size="l">
                  <Textarea 
                  class="description"
                  onChange={({ detail }) => setUpdateComments(detail.value)}
                  value={updateComments}
                  placeholder="Comments"
                  ></Textarea>
                  <div className="buttons">
                <SpaceBetween direction="horizontal" size="l">
                  {enableEditAction ? <Button variant="primary" onClick={updateApplication}  >Update</Button> :
                    <Button variant="primary" onClick={updateApplication} disabled={enableEditAction} >Update</Button>}

                  <Button onClick={cancelEdition}>Cancel</Button>
                </SpaceBetween>
              </div>
                </SpaceBetween>
              :
              ''
          }
        </SpaceBetween>
      
        {modalMessage ?
          <Modal
            onDismiss={() => { setModalMessage("") }}
            visible={modalMessage}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" onClick={() => { setModalMessage("") }}>Ok</Button>
                </SpaceBetween>
              </Box>
            }
          >
            <Box>{modalMessage}</Box>
          </Modal> : ''}
    </div>
  );

  const appHeader = (
    <SpaceBetween size="l">
            {
            alertErrorMessage?<Alert
            statusIconAriaLabel="Error"
            type="error"
            header={alertErrorMessage?.header}
          >
            {alertErrorMessage?.message}
          </Alert>:''
          }
            {
              isEditable(TEMPLATE_APP.APP_NAME) ?
                <Box> <CustomInput field={TEMPLATE_APP.APP_NAME} placeholder="App name" updateValue={updateValue} getValue={getValue} required={true} mandatoryFieldListener={mandatoryFieldListener}></CustomInput> </Box> :
                <Box variant="h2">
                  <span className="custom-home__header-title">{usecaseName}</span>
                  <span></span>
                  <img className="favorite_icon" src={isFavorite ? favLogoSolid : favLogo} onClick={() => markFavorite(id)} ></img>
                  {
                    isNewApp ?
                      '' :
                      canEdit ?
                        (isEditMode) ?
                          '' :
                          <span>
                            <Button iconName="edit" variant="icon" onClick={editApplication} />
                            {canDelete ? <Button iconName="remove" variant="icon" onClick={() => { setDeleteConfirmationModal(true) }} /> : ''}
                            <Modal
                              onDismiss={dismissDeleteDialog}
                              visible={deleteConfirmationModal}
                              footer={
                                <Box float="right">
                                  <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="link" onClick={dismissDeleteDialog} >Cancel</Button>
                                    <Button disabled={deleteAppName !== renderJson[TEMPLATE_APP.APP_NAME]} variant="primary" onClick={deleteApplication}  >Confirm</Button>
                                  </SpaceBetween>
                                </Box>
                              }
                              header={
                                <React.Fragment>
                                  <Box variant="h2">
                                    Delete confirmation for '{renderJson[TEMPLATE_APP.APP_NAME]}'
                                  </Box>
                                </React.Fragment>
                              }
                            >
                              <SpaceBetween direction="vertical" size="m">
                              <Box>To confirm deletion, enter the name of the app in the text input field below</Box>
                              <Box>
                                <Input placeholder={renderJson[TEMPLATE_APP.APP_NAME]} onChange={({ detail }) => setDeleteAppName(detail.value)} value={deleteAppName} />
                              </Box>
                      <Box>
                        <Textarea
                          class="description"
                          onChange={({ detail }) => setUpdateComments(detail.value)}
                          value={updateComments}
                          placeholder="Comments"
                        ></Textarea>
                      </Box>
                      <Box>
                        {modalError && (
                          <Box variant="p" color="text-status-error">
                            {modalError}
                          </Box>
                        )}
                      </Box>
                    </SpaceBetween>

                              
                              
                            </Modal>
                            <>{lastUpdatedContent}</>
                          </span>
                        :
                        <>{lastUpdatedContent}</>
                  }
                </Box>
            }
            {isEditable(TEMPLATE_APP.APP_DESCRIPTION)
              ?
              <Grid
                gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
              >
                <div> <Textarea
                  class="description"
                  onChange={({ detail }) => updateValue(TEMPLATE_APP.APP_DESCRIPTION, detail.value)}
                  value={getValue(TEMPLATE_APP.APP_DESCRIPTION)}
                  placeholder="Description"
                /> </div>
                <div> <span className="mandatory">*</span> </div>
              </Grid>

              : <Box class="description" variant="p">
                <span className="custom-home__category">{usecaseDescription}</span></Box>
            }

            {
              isEditable(TEMPLATE_APP.STATUS)
                ?
                <CustomSelect field={TEMPLATE_APP.STATUS} getValue={getValue} updateValue={updateValue} getOptions={getSelectFieldValues} />
                :
                <span className="custom-home__category">
                  {usecaseStatus} &nbsp;
                  {usecaseStatus === "Active" ? (
                    <Icon
                      variant="success"
                      name="status-positive"
                      size="small"
                    ></Icon>
                  ) : usecaseStatus === "Deprecated" ? (
                    <Icon
                      variant="error"
                      name="status-negative"
                      size="small"
                    ></Icon>
                  ) : (
                    <Icon
                      variant="normal"
                      name="status-in-progress"
                      size="small"
                    ></Icon>
                  )}
                </span>
            }
            {
              isEditable(TEMPLATE_APP.FLASH_INFO_DESCRIPTION) ?
                <div className="comments">
                  <CustomInput field={TEMPLATE_APP.FLASH_INFO_DESCRIPTION} placeholder="Comments" updateValue={updateValue} getValue={getValue}></CustomInput>
                </div>
                :
                <Box> {usecaseComments ?
                  <Flashbar items={
                    flashBarComments
                  } /> : <Box></Box>
                }
                </Box>
            }
            {usecaseDevComments ?
              <Flashbar items={
                flashBarDevComments
              } /> :
              ''}
          </SpaceBetween>
  )

  const saveAnnotated = () => {
    setAnnotationLoader("Saving the data..")
    let data = gridRef.current?.api.getDataAsCsv()
    Storage.put(annotationFileName, data, {
      level: annotationLevel,
      customPrefix: { public: annotationPrefix }
  })
  .then (result => getAnnotationInput())
  .catch(err => setAnnotationError("Saving failed"));
  }

  const addRowToAnnotation = () => {
    dsInfo.splice(selectedRowIndex, 0, {});
    setDsInfo([...dsInfo])
    setUndoEditCount(undoEditCount+1)
  }
  const delRowToAnnotation = () => {
    dsInfo.splice(selectedRowIndex, 1);
    setDsInfo([...dsInfo])
    setUndoEditCount(undoEditCount+1)
  }

  const exportAnnotated = (filtered=false) => {
    let csv = gridRef.current?.api.exportDataAsCsv({
      'fileName': annotationFileName,
      'exportedRows': filtered? 'filteredAndSorted' : 'all'
    });
    // gridRef.current?.api.exportDataAsExcel()
  }

  const onCellValueChanged = useCallback((params) => {
    const undoSize = params.api.getCurrentUndoSize();
    setUndoEditCount(undoSize)
    
    if (params.oldValue !== params.newValue) {
      let editedCols = params.data.isEdited || {}
      if(editedCols.hasOwnProperty(params.column.colId)){
        // store original old value
        editedCols[params.column.colId] = {oldValue: editedCols[params.column.colId]?.oldValue, newValue: params.newValue}
      }else{
        // new entry
        editedCols[params.column.colId] = {oldValue: params.oldValue, newValue: params.newValue}
      }
      params.data.isEdited = editedCols; 
   }
   params.api.refreshCells();

    // cellRendererSelector: (params) => {
    //   if (params.node.rowPinned) {
    //     return {
    //       component: CustomPinnedRowRenderer,
    //       params: {
    //         style: { color: "#5577CC" },
    //       },
    //     };
    //   } else {
    //     // rows that are not pinned don't use any cell renderer
    //     return undefined;
    //   }
    // }
  }, []);

  const onCellFocused = useCallback((params) => {
    setSelectedRowIndex(params.rowIndex)
  }, []);
  const undo = useCallback(() => {
    gridRef.current.api.undoCellEditing();
  }, []);

  const redo = useCallback(() => {
    gridRef.current.api.redoCellEditing();
  }, []);

  const handleViewChanges = () => {
      setShowOldValues(!showOldValues)
  }
  const friscoAnnotation = (
    <SpaceBetween direction="vertical" size="m">
<Container
    >
      <SpaceBetween direction="horizontal">
      <FileUpload
        onChange={({ detail }) => {
          setAnnotationInputFile(detail.value);
        }}
        value={annotationInputFile}
        i18nStrings={{
          uploadButtonText: e =>
            e ? "Choose files" : "Choose file",
          dropzoneText: e =>
            e
              ? "Drop files to generate"
              : "Drop file to generate",
          removeFileAriaLabel: e =>
            `Remove file ${e + 1}`,
          limitShowFewer: "Show fewer files",
          limitShowMore: "Show more files",
          errorIconAriaLabel: "Error"
        }}
        showFileLastModified
        showFileSize
        showFileThumbnail
        tokenLimit={1}
        accept=".csv"
        constraintText="upload input file"
      />

      {
        annotationInputFile && annotationInputFile.length > 0? <Button variant="primary" onClick={uploadAnnotationInput}>Upload</Button> : ''
      }
      
    </SpaceBetween>
    
    </Container>
<Container disableContentPaddings={true}>
  <SpaceBetween direction="vertical">
  <Grid
      gridDefinition={[
        { colspan: { default: 3, xxs: 3 } },
        { colspan: { default: 9, xxs: 9 } }
      ]}
    >
  <Box  padding="m">
      <SpaceBetween direction="horizontal" size="xs">
        { annotationLoader ? <Box> <Spinner></Spinner>{annotationLoader}</Box> : <></>}
        { annotationError ? <Box> <span className="error_message">{annotationError}</span> </Box> : <></>}
      </SpaceBetween>
    </Box>
  <Box float="right" padding="m">
      <SpaceBetween direction="horizontal" size="xl">
      {/* <Button variant="icon" iconName="undo" onClick={(()=>undo())}></Button>
      <Button variant="icon" iconName="redo" onClick={(()=>redo())}></Button> */}

       { undoEditCount ? <Button onClick={(()=>saveAnnotated())}>Save</Button> : <></> }
      <ButtonDropdown
      items={[
        { text: "Current view", id: "current_view", disabled: false },
        { text: "Original", id: "original", disabled: false },
      ]}
      onItemClick={(event)=> {
        if(event.detail.id == 'current_view'){
          exportAnnotated(true)
        }else if(event.detail.id == 'original'){
          exportAnnotated()
        }
      }}
    >
      Export
    </ButtonDropdown>
    <Button onClick={(()=>addRowToAnnotation())}>+ Row</Button>
    <Button onClick={(()=>delRowToAnnotation())}>- Row</Button>
    {/* <Button variant="icon" iconName="unlocked" onClick={(()=>handleViewChanges())}></Button> */}
      <Button variant="icon" iconName="refresh" onClick={(()=>getAnnotationInput())}></Button>
      </SpaceBetween>
    </Box>
  </Grid>
    <div className="ag-grid-container ">
    <div
    className="ag-theme-alpine" style={{height: '70vh', width: "100%"}}
    // className="ag-theme-alpine"
    >
    <AgGridReact
                ref={gridRef}
                          rowData={dsInfo}
                          defaultColDef={defaultProjectColDef}
                          columnDefs={columnsList}
                          rowSelection="single"
                          rowNumbers={true}
                          editType={"fullRow"}
                          animateRows={true}
                          rowHeight={30}
                          enableCellTextSelection={true}
                          stopEditingWhenCellsLoseFocus={true}
                          undoRedoCellEditing={true}
                          cellSelection={true}
            undoRedoCellEditingLimit={10}
            onCellValueChanged={onCellValueChanged}
            onCellFocused={onCellFocused}
              />
</div>
</div>
  </SpaceBetween>

</Container>
    </SpaceBetween>
    
    
  )

  const reports = (
    <SpaceBetween direction="vertical">
      <Tabs
        onChange={({ detail }) =>
          setActiveReportTabId(detail.activeTabId)
        }
        activeTabId={activeReportTabId}
        tabs={quicksightReports}
        disableContentPaddings={true}
      />
      {
        embedUrlLoadingMessage?
        <Box><br></br> <Spinner></Spinner>  {embedUrlLoadingMessage} <br></br></Box>:<></>
      }
      {
        embedUrl ?
        <div>
        <iframe
          className="qs_dash"
          src={embedUrl}>
        </iframe>
      </div> : <></>
      }
    </SpaceBetween>
  )

  const Content = (
    <>
      {showLoadingIcon ? (
        <Modal visible={showLoadingIcon}>
          <Box>
            <Spinner></Spinner>
            Loading...
          </Box>
        </Modal>
      ) : (<Box
      >
        <div class="app_header custom-home__header">
        {appHeader}
        </div>
        <div class="app_details">
        <Tabs
            onChange={({ detail }) =>
              setActiveTabId(detail.activeTabId)
            }
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Details",
                id: "details",
                content: appDetails
              },
              {
                label: "Reports",
                id: "reports",
                content: reports
              },
              {
                label: "FRISCO",
                id: "frisco",
                content: friscoAnnotation,
                disabled: (currentStage == 'beta' || currentStage == 'dev') && id == '265' ? false:true
              }
              ,
              // {
              //   label: "Logico",
              //   id: "logico",
              //   content: reports,
              //   disabled: true
              // },
              // {
              //   label: "FlexiCo",
              //   id: "flexico",
              //   content: reports,
              //   disabled: true
              // }
            ]}
            disableContentPaddings={false}
          />
        </div>
        <br />
        <div class="app_details">
        {
          !isNewApp && !isEditMode ?
            <SpaceBetween size="l" >
              <Feedback appId={id} pageId={id} userId={userId} parentId={"null"} level={0} limit={1}></Feedback>
            </SpaceBetween> : ''
        }
        </div>
        <br />
      </Box>)
      }

    </>
  )

  const helpContent = (
    <HelpPanel header={<Box variant="h2">Glossary</Box>}>
      <Box>
        <ul>
          {glossary.map((item) => (
            <li key={item.label} className="list-item-data">
              <ExpandableSection
                variant="navigation"
                headerText={item.label}
              >
                {item.description}
              </ExpandableSection>
            </li>
          ))}
        </ul>
      </Box>
    </HelpPanel>
  );

  return (
    <AppLayout
      disableContentPaddings={true}
      content={Content}
      navigation={<SideNav activeHref={`/App/${id}`} id={id} applicationName={usecaseName} customSideNavitems={customSideNavitems} />}
      contentType="default"
      navigationOpen={NavOpen}
      onNavigationChange={() => {
        setNavOpen(!NavOpen);
      }}
      tools={helpContent}
      toolsOpen={toolsOpen}
      onToolsChange={() => { setToolsOpen(!toolsOpen) }}
      headerVariant="high-contrast"
    />
  );
};
