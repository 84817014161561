import React, { useState, useEffect } from "react";
import {
    Box,
    Input,
    Button,
    Select,
    SpaceBetween,
    TokenGroup,
    Container,
    Grid
} from "@amzn/awsui-components-react";
import './style.scss'

const CustomMultiValuedParameter = (props) => {
    var [rows, setRows] = useState(props.getValues(props.field, null) || []);
    var [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        loadRows()
        return () => {
        };
    }, []);

    useEffect(() => {
        props.updateValue(props.field, getMergedInputValues())
    }, [rows])

    const getMergedInputValues = () => {
        var filtered = rows?.filter(function (row) {
            return row != null;
        });
        for (let i = 0; i < filtered?.length; i++) {
            let transformedVals = []
            if (filtered[i]['field_type'] == 'input') {
                transformedVals.push(' ')
            } else {
                let vals = filtered[i]['field_tokens']
                for (let j = 0; j < vals?.length; j++) {
                    transformedVals.push(vals[j]?.label)
                }
            }

            filtered[i]['field_values'] = transformedVals
        }
        return filtered
    }

    const onEdit = (value, index, key) => {
        rows[index][key] = value
        setRows([...rows])
    }

    const getInputItem = (value, index) => {
        if (props?.hideLabelPrefix && value.hasOwnProperty('label') && value['label']?.startsWith(props?.hideLabelPrefix)) {
            return <></>
        } else {
            return <Container >
                <div key={index}>

                    <Grid
                        gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                    >
                        <div>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Select
                                    selectedOption={{ label: value?.field_type, value: value?.field_type }}
                                    onChange={({ detail }) => {
                                        onEdit(detail.selectedOption.value, index, 'field_type')
                                    }
                                    }
                                    options={[
                                        { label: "select", value: "select" },
                                        { label: "multi_select", value: "multi_select" },
                                        { label: "input", value: "input" },
                                        { label: "fixed_inputs", value: "fixed_inputs" }
                                    ]}
                                    disabled={!canDelete(index)}
                                />
                                <Input
                                    onChange={({ detail }) => {
                                        let val = detail.value
                                        if (props?.postProcess) {
                                            val = props.postProcess(detail.value)
                                        }
                                        onEdit(val, index, 'label')
                                    }}
                                    placeholder="label"
                                    value={value.hasOwnProperty('label') ? value['label'] : ''}
                                    disabled={!canDelete(index)}
                                />
                                <SpaceBetween direction="vertical" size="m">
                                    <div className="param_input">
                                        <Input
                                            onChange={({ detail }) => {
                                                let val = detail.value
                                                onEdit({ label: val.trim(), dismissLabel: val.trim() }, index, 'typed_value')

                                            }}
                                            onKeyDown={({ detail }) => {
                                                if (detail.key == 'Enter') {
                                                    if (value['typed_value'] == undefined || value['typed_value']?.label == undefined || value['typed_value']?.label == '') {

                                                    } else {
                                                        value['field_tokens'].push(value['typed_value'])
                                                        // resetting the typed value for new values. Existing value is already added to token list
                                                        onEdit(undefined, index, 'typed_value')
                                                    }
                                                }
                                            }}
                                            placeholder="values"
                                            value={value?.typed_value?.label}
                                            disabled={value?.field_type == 'input' ? true : (value.limit && value['field_tokens']?.length >= value.limit ? true : false)}
                                        />
                                        {
                                            value?.typed_value?.label? <span className="hintMessage">Press Enter for the values to be considered</span>:''
                                        }
                                    </div>


                                    <TokenGroup
                                        onDismiss={({ detail: { itemIndex } }) => {
                                            let arr = value?.field_tokens
                                            arr.splice(itemIndex, 1)
                                            onEdit(arr, index, 'field_tokens')
                                        }}
                                        items={value?.field_tokens}
                                    />
                                </SpaceBetween>
                            </SpaceBetween>
                        </div>
                        {
                            index != null && index != undefined && canDelete(index) ? <div className="row"><Button iconName="close" variant="icon" onClick={() => {
                                deleteRow(index)
                            }} /></div> : ''
                        }

                    </Grid>
                </div>
            </Container>
        }
    }
    const canDelete = (index) => {
        let canDelete = true
        for (let i = 0; i < props?.mandatoryParameters?.length; i++) {
            if (props.mandatoryParameters[i].label == rows[index].label && props.mandatoryParameters[i].field_type == rows[index].field_type) {
                canDelete = false
                break
            }
        }
        return canDelete
    }
    const deleteRow = (index) => {
        if (canDelete(index)) {
            rows.splice(index, 1)
            setRows([...rows])
        }

    }
    const addRow = () => {
        var newRow = {}
        newRow['label'] = ''
        newRow['field_tokens'] = []
        newRow['field_type'] = []
        setRows([...rows, newRow])
    }
    const getAddButton = () => {
        return <div> <Button onClick={addRow}>Add</Button> </div>
    }

    const loadRows = () => {
        let values = props.getValues(props.field, null)
        if (values) {
            for (let i = 0; i < props?.mandatoryParameters?.length; i++) {
                let found = false
                for (let j = 0; j < values?.length; j++) {
                    if (values[j]?.label == props.mandatoryParameters[i].label) {
                        found = true
                        break
                    }
                }
                if (!found) {
                    values.push({ 'field_type': props.mandatoryParameters[i].field_type, 'label': props.mandatoryParameters[i].label, 'limit': props.mandatoryParameters[i].limit, 'field_values': '', 'field_tokens': [] })
                }
            }

        } else {
            values = []
            for (let i = 0; i < props?.mandatoryParameters?.length; i++) {
                values.push({ 'field_type': props.mandatoryParameters[i].field_type, 'label': props.mandatoryParameters[i].label, 'limit': props.mandatoryParameters[i].limit, 'field_values': '', 'field_tokens': [] })
            }
        }
        if (values) {
            for (let i = 0; i < values?.length; i++) {
                if (values[i].hasOwnProperty('field_values') && !values[i].hasOwnProperty('field_tokens')) {
                    let tokens = []
                    for (let j = 0; j < values[i]['field_values']?.length; j++) {
                        let val = values[i]['field_values'][j]
                        tokens.push({ label: val, dismissLabel: val })
                    }
                    values[i]['field_tokens'] = tokens
                }
                if(values[i].label == 'contico_job' && values[i].field_type == 'fixed_inputs' && !values[i].hasOwnProperty('limit')){
                    values[i]['limit'] = 1
                }
            }
            setRows(JSON.parse(JSON.stringify(values)))
        } else {
            setRows([])
        }
    }

    return (
        <Box>
            {props.info ? <span className="info">{props.info}</span> : ''}
            <br></br>
            {errorMessage ? <span className="error"> {errorMessage} </span> : ''}
            <br></br>
            {rows ? (Array.from(rows).map((value, index) => {
                return getInputItem(value, index);
            })) : ''}
            <br></br>
            {
                props?.allowAddition ?
                getAddButton() : <></>
            }
        </Box>
    )
}

export default CustomMultiValuedParameter;